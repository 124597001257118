import Swal from "sweetalert2";
const customalertinstance = (x) => {
    if(x.data.error === false) {
        return Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
          }).then((result) => {
            if (result.isConfirmed) {
            //   Swal.fire({
            //     title: "Deleted!",
            //     text: "Your file has been deleted.",
            //     icon: "success"
            //   });
              return true
            }else{
              return false
            }
          });
        // Swal.fire({
        //     title: 'notification',
        //     text: x.data.message,
        //     icon: 'success',  
        // })
    }
    else{
        Swal.fire({
            title: 'error',
            text: x.data.message,
            icon: 'error',
            })
    }
}   

export default customalertinstance; 
