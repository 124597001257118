import React,{useState,useEffect} from 'react'
import { useParams,useNavigate } from 'react-router-dom';
import { useUserAuth } from '../../Context/UserAuthContext';
import Select from 'react-select';
import { countries,Listdevice,Listplatform,Listtags,listCategory,listSubCategory, Listgenre} from '../../../Objects/Country';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AsyncSelect from 'react-select/async';
import { Toaster } from 'react-hot-toast';
import toaster from '../../../Objects/toaster';
const Addnewcontent = () => {
    let {templateName,tab,contentId,contentName,type} = useParams();
    let navigate = useNavigate();
    let formData = new FormData();
    const {listTemplates,listContents,addContents,updateContents} = useUserAuth();
    const [metaData,setMetaData]=useState({})
    const [param,setParam]=useState({})
    const [text,setText]=useState("<p><strong><em><u>TEST</u></em></strong></p>")
    const [fields,setFields]=useState([])
    const [category,setCategory]=useState([])
    const [countryFiltered,setCountryFiltered]=useState([])
    const [subcategory,setSubcategory]=useState([])
    const [genre,setGenre]=useState([])
    const [tag,setTag]=useState([])
    const [platform,setplatform]=useState([])
    useEffect(()=>{
        const fetchData=async(type)=>{
            let res,data = "";
            if(type === "add"){
                res = await listTemplates();
                data = res.data.data.filter(x=>x.title === templateName)
                if(data && data.length > 0){
                    res = await listContents();
                    let defLabel = data[0].data; 
                    setFields(defLabel)     
                    setParam({title:'',description:'',production:'',director:'',actors:[],freeTrailer:[],paidTrailer:[],
                        portraitPosterIdSmall:'',portraitPosterIdNormal:'',landscapePosterIdSmall:'',landscapePosterIdNormal:'',
                        categoryName:'',rank:res.data.data.length + 1
                    })
                }
            }else{
                res = await listContents();
                data = res.data.data.filter(x=>x._id === contentId)
                const cu =data[0];
                setParam(cu)
                setMetaData(cu)
                transCountry(cu);
                let result = Object.entries(cu).map(([key,value])=>({
                    id:key,text:key,value:value
                }));
                //sorting based on aplhabatical Order
                result.sort((a,b)=> a.id.localeCompare(b.id))
                setFields(result)
            }
          }
        fetchData(type)
    },[])
    const transCountry=async(data)=>{
        const ss = countries;
        let allCategoryList = await listCategory();
        let allSubcategoryList = await listSubCategory();
        let country = data.countryFiltered;
        let categoryName = data.categoryName;

        const countrySet = new Set(country);
        const subSet  = new Set(data.subcategory);

        const arr = ss.filter(item => countrySet.has(item.value));
        const catArr = allCategoryList.filter(item => categoryName === item.title);
        const subArr = allSubcategoryList.filter(item => subSet.has(item.title) )

        setSubcategory(subArr)
        setCategory(catArr)

        const updatedPlatform = data.deviceFiltered.map(x => ({ label: x, value: x }));
        const updatedGenre = data.genre.map(x => ({ label: x, value: x }));
        const updatedTags = data.tags.map(x => ({ label: x, value: x }));

        setplatform(updatedPlatform);
        setTag(updatedTags)
        setGenre(updatedGenre)
        setCountryFiltered(arr)
    }
    const handleFieldChange=(e,x)=>{
        switch(x.id){
            case 'title':
                return setParam({...param, title:e.target.value })
            case 'cast':
               return setParam({...param, actors:e.target.value })
            case 'actors': 
               return setParam({...param, actors:e.target.value })   
            case 'releaseDate':
                return setParam({...param, releaseDate:e.target.value })
            case 'description':              
               return setParam({...param, description:e}) 
            case 'published':
                return setParam({...param, published:e.target.checked })
            case 'active':
                return setParam({...param, active:e.target.checked })
            case 'tags':
                return setTag(Array.isArray(e)?e.map(x=>x):[])
            case "countryFiltered":
                return  setCountryFiltered(Array.isArray(e)?e.map(x=>x):[])
            case "deviceFiltered":
                setplatform(Array.isArray(e)?e.map(x=>x):[])
                return// setParam({...param, deviceFiltered:Array.isArray(e)?e.map(x=>x.label):[] })
            case 'categoryName':
                setCategory(e)
                return  setParam({...param, categoryName:e.title})
            case 'category':
                setCategory(e)
                return setParam({...param, categoryName:e.title})
            case 'subcategory':
                setSubcategory(Array.isArray(e)?e.map(x=>x):[])
                return //setParam({...param, subcategory:Array.isArray(e)?e.map(x=>x.title):[]})
            case 'censor':
                return setParam({...param, censor:e.target.checked})
            case 'censorCertificate':
                return setParam({...param, censorCertificate:e.target.files[0] })
            case 'uploadCensorCertificate':
                return setParam({...param, uploadCensorCertificate:e.target.files[0]})
            case 'genre':
                setGenre(Array.isArray(e)?e.map(x=>x):[])
                return //setParam({...param, genre:Array.isArray(e)?e.map(x=>x.value):[] })
            case 'offlineStreaming':
                return setParam({...param, offlineStreaming:e.target.checked })
            case 'ageGroup':
                return setParam({...param, ageGroup:e.target.value })
            case 'director':
                // return setParam({...param, director:Array.isArray(sepVal)?sepVal.map(x=> x.trim() ):[] })
                return setParam({...param, director:e.target.value })
            case 'production':
                return setParam({...param, production:e.target.value })
            case 'otherCastCrew':
                return setParam({...param, otherCastCrew:e.target.value})
                //return setParam({...param, otherCastCrew:Array.isArray(e)?e.map(x=>x.value):[]})
            case 'producer':
                return setParam({...param, producer:e.target.value })
            case 'freelyAvailable':
                return setParam({...param, freelyAvailable:e.target.checked })
            case 'canWatchWithoutLogin':
                return setParam({...param, canWatchWithoutLogin:e.target.checked })
            case 'portraitPosterSmall':
                return setParam({...param, portraitPosterIdSmall:e.target.files[0] })
            case 'portraitPosterIdSmall':
                return setParam({...param, portraitPosterIdSmall:e.target.files[0] })
            case 'portraitPosterNormal':
                return setParam({...param, portraitPosterIdNormal:e.target.files[0] })
            case 'portraitPosterIdNormal':
                return setParam({...param, portraitPosterIdNormal:e.target.files[0] })
            case 'webPortraitPosterNormal':
                return setParam({...param, webPosterPortraitNormal:e.target.files[0] })
            case 'webPosterPortraitNormal':
                return setParam({...param, webPosterPortraitNormal:e.target.files[0] })
            case 'landscapePosterSmall':
                return setParam({...param, landscapePosterIdSmall:e.target.files[0] })
            case 'landscapePosterIdSmall':
                return setParam({...param, landscapePosterIdSmall:e.target.files[0] })
            case 'landscapePosterNormal':
                return setParam({...param, landscapePosterIdNormal:e.target.files[0] })
            case 'landscapePosterIdNormal':
                return setParam({...param, landscapePosterIdNormal:e.target.files[0] })
            case 'webLandscapePosterNormal':
                return setParam({...param, webPosterLandscapeNormal:e.target.files[0] })
            case 'webPosterLandscapeNormal':
                return setParam({...param, webPosterLandscapeNormal:e.target.files[0] })
            case 'genericTrailer':
                return setParam({...param, genericTrailer:e.target.value })
            case 'freeTrailer':
                return setParam({...param, freeTrailer:e.target.value })
            case 'paidTrailer':
                return setParam({...param, paidTrailer:e.target.value })
            case 'trailerAvailable':
                return setParam({...param, trailerAvailable:e.target.checked })
            case 'videoAvailable':
                return setParam({...param, videoAvailable:e.target.checked })    
            case 'isTrailer':
                return setParam({...param, isTrailer:e.target.checked })
            case 'canShowInTV':
                return setParam({...param, canShowInTV:e.target.checked })
            case 'haveSeason':
                return setParam({...param, haveSeason:e.target.checked })  
            case 'authenticationNeeded':
                return setParam({...param, authenticationNeeded:e.target.checked })  
            case 'trailerFileUrl':
                return setParam({...param, trailerFileUrl:e.target.value })  
            default :
                return 
        }
    }
    const keysToExclude = [ "_id", "seasons", "__v", "contentId", 
        "categoryCode", "categoryId", "numClicked", 
        "numComments", "ratings", "awsStaticResourcePath", 
        "rank", "statitics", "comments","templateName"];
    const keysToExcludeParam = [
        "_id", "seasons", "__v", 
        "categoryCode", "categoryId", "numClicked", 
        "numComments", "ratings", "awsStaticResourcePath", "statitics", "comments","webLandscapePosterNormal", "webPosterPortraitNormal", "webPortraitPosterNormal",
        "portraitPosterSmall", "portraitPosterNormal", "landscapePosterSmall",
        "landscapePosterNormal", "webPosterLandscapeNormal", "portraitPosterIdNormal",
        "portraitPosterIdSmall","landscapePosterIdNormal","landscapePosterIdSmall","templateName",
    ]
    const checkboxFields = [
        "published", "active", "haveSeason", "trailerAvailable", 
        "canShowInTV", "freelyAvailable", "canWatchWithoutLogin", 
        "authenticationNeeded","censor","isTrailer","videoAvailable"
    ];
    const fileInputFields = [
        "webLandscapePosterNormal", "webPosterPortraitNormal", "webPortraitPosterNormal",
        "portraitPosterSmall", "portraitPosterNormal", "landscapePosterSmall",
        "landscapePosterNormal", "webPosterLandscapeNormal", "portraitPosterIdNormal",
        "portraitPosterIdSmall","landscapePosterIdNormal","landscapePosterIdSmall"
    ];
    const formatContents=(value)=>{
        let sepVal = ""
        if(Array.isArray(value)){
           sepVal = value;
        }else{
           sepVal = value.split(",");
        }
        return sepVal.map(text=> text.replace(/\b\w/g, (char) => char.toUpperCase()).replace(/\B\w+/g, (char) => char.toLowerCase()))
    }
    const isObjectFull = (obj) => {
        return Object.values(obj).every(value => value !== "" && value !== null && value !== undefined && value.length !== 0);
    };
    const addFieldToFormData = (fieldName, paramValue, metaDataValue) => {
        if ((type === 'add' && paramValue !== undefined && paramValue !== '') ||  (type === 'update' && paramValue !== metaDataValue)) {
          formData.append(fieldName, paramValue);
        }
      };
    const handleSubmit=async()=>{
        let result = {};
        let cusflag = false;
        result = Object.fromEntries(
            Object.entries(param).filter(([key]) => !keysToExcludeParam.includes(key))
        );
        if(type === 'update'){
            cusflag =  isObjectFull(result)
        }else{
            cusflag =  isObjectFull(param);
        }
        if(cusflag){
            result["title"] = param.title.trim();
            const textOnly = param.description.replace(/<[^>]+>/g, ''); // Remove HTML tags
            result["description"] = textOnly
            result["actors"] = formatContents(param.actors);
            result["countryFiltered"]=countryFiltered.map(x=>x.value);
            result["deviceFiltered"]=platform.map(x=>x.value);
            result["tags"]=tag.map(x=>x.value);
            result["genre"]=genre.map(x=>x.value);
            result["subcategory"]=subcategory.map(x=>x.title);
            if(param.trailerFileUrl){
                result["trailerFileUrl"] = Array.isArray(param.trailerFileUrl) ? param.trailerFileUrl:[param.trailerFileUrl];
            }           
            result["paidTrailer"] = Array.isArray(param.paidTrailer) ? param.paidTrailer:[param.paidTrailer];
            result["freeTrailer"] = Array.isArray(param.freeTrailer) ? param.freeTrailer:[param.freeTrailer];
            addFieldToFormData('portraitSmall', param.portraitPosterIdSmall, metaData.portraitPosterIdSmall);
            addFieldToFormData('portraitNormal', param.portraitPosterIdNormal, metaData.portraitPosterIdNormal);
            addFieldToFormData('landscapeSmall', param.landscapePosterIdSmall, metaData.landscapePosterIdSmall);
            addFieldToFormData('landscapeNormal', param.landscapePosterIdNormal, metaData.landscapePosterIdNormal);
            addFieldToFormData('webPosterPortraitNormal', param.webPosterPortraitNormal, metaData.webPosterPortraitNormal);
            addFieldToFormData('webPosterLandscapeNormal', param.webPosterLandscapeNormal, metaData.webPosterLandscapeNormal);
           
            let categoryObj = {};
            if(Array.isArray(category)){
                categoryObj= category[0];
            }else{
                categoryObj = category;
            }
            result["categoryName"]=categoryObj.title;
            result["categoryId"]=categoryObj._id;
            result["categoryCode"]=categoryObj.code;
            const formText =JSON.stringify(result);
            formData.append('formText', formText);
            const body = formData;
            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }
            let res={};
            if(type !== "add"){
                res = await updateContents(body);
                handleBack(res)
            }else{
                console.log(body)
                res = await addContents(body);
                console.log(res)
                handleBack(res)
            }
        }else{
            toaster("Please Fill All The Mandatory Fields ...","error")  
        }
    }
    const handleBack=(res)=>{
        if(!res.data.error){
            toaster(res.data.message,"success")
            setTimeout(()=>{
                navigate(-1)
            },[1500])
        }else{
            toaster(res.data.message,"error")
        }
    }
  return (
    <div className='Temp'>
        <div className='advForm'>
        <p>you are adding content using <span>{templateName}</span></p>
        {fields.map((x,index)=>{
           if (checkboxFields.includes(x.id)) {
            return (
              <div key={index}>
                <input 
                  type="checkbox" 
                  checked={param[x.id]} 
                  onChange={(e) => handleFieldChange(e, x)}
                />
                <label>{x.text}</label>
              </div>
            );
          } else if(x.id === "countryFiltered"){
                return (
                    <div key={index}>
                   <label>{x.text}</label>
                   <Select className="select form-control p-0" isMulti value={countryFiltered}  options={countries} onChange={(e)=>handleFieldChange(e,x)}></Select>
                    </div>
                )
            }else if(x.id === "deviceFiltered"){
                return (
                    <div key={index}>
                   <label>{x.text}</label>
                   <Select className="select form-control p-0" isMulti value={platform} options={Listdevice} onChange={(e)=>handleFieldChange(e,x)}></Select>
                    </div>
                )
            }else if(x.id === "tags"){
                return (
                    <div>
                   <label>{x.text}</label>
                   <Select className="select form-control p-0" isMulti value={tag} options={Listtags} onChange={(e)=>handleFieldChange(e,x)}></Select>
                    </div>
                )
            }else if(x.id === "genre"){
                return (
                    <div>
                   <label>{x.text} <span className="required">*</span></label>
                   <Select className="select form-control p-0" isMulti value={genre} options={Listgenre} onChange={(e)=>handleFieldChange(e,x)}></Select>
                    </div>
                )
            }else if(x.id === "category" || x.id === "categoryName"){
                return (
                    <div>
                   <label>{x.text} <span className="required">*</span></label>
                   <AsyncSelect
                        cacheOptions
                        defaultOptions
                        value={category}
                        defaultValue={category}
                        getOptionLabel={e => e.title}
                        getOptionValue={e => e._id}
                        loadOptions={listCategory}
                        onChange={(e)=>handleFieldChange(e,x)}
                        placeholder={x.text}
                        className="select form-control p-0"
                    />
                  
                    </div>
                )
            }else if(x.id === "subcategory"){
                return (
                    <div>
                   <label>{x.text} <span className="required">*</span></label>
                   <AsyncSelect
                        isMulti
                        cacheOptions
                        defaultOptions
                        value={subcategory}
                        getOptionLabel={e => e.title}
                        getOptionValue={e => e._id}
                        loadOptions={listSubCategory}
                        onChange={(e)=>handleFieldChange(e,x)}
                        className="select form-control p-0"
                        placeholder={x.text}
                    />
                    </div>
                )
            }else if(fileInputFields.includes(x.id)){
                return (
                    <div>
                   <label>{x.text} <span className="required">*</span></label>
                   <input
                        className="form-control"
                        type="file"
                        onChange={(e)=>handleFieldChange(e,x)}
                    />
                    </div>
                )
            }else if(x.id === "cast" || x.id === "actors"){
                return (
                    <div>
                    <label>{x.text} <span className="required">*</span></label><br/>
                    <input type="text" placeholder={x.text} value={param[x.id]} onChange={(e)=>handleFieldChange(e,x)}/>
                    </div>
                )
            }else if(x.id === "releaseDate"){
                return (
                    <div>
                    <label>{x.text} <span className="required">*</span></label><br/>
                    <input type="date" placeholder={x.text}  value={param[x.id]} onChange={(e)=>handleFieldChange(e,x)}/>
                    </div>
                )
            }else if(x.id === "description"){
                return (
                    <div>
                    <label>{x.text} <span className="required">*</span></label><br/>
                    <ReactQuill className="reactQuill" value={param[x.id]} onChange={(e)=>handleFieldChange(e,x)} />
                    </div>
                )
            }else if(keysToExclude.includes(x.id)){
                return 
            }else{
                return (
                    <div>
                    <label>{x.text} <span className="required">*</span></label><br/>
                    <input type="text" placeholder={x.text} value={param[x.id]} onChange={(e)=>handleFieldChange(e,x)}/>
                    </div>
                )
            }
           
        })}

        <button className='btnSubmit' onClick={handleSubmit}  >Submit</button>
        <button className='btnSubmit btn-danger' onClick={()=>navigate(-1)}>Back</button>
        </div>
        <Toaster/>
    </div>
  )
}

export default Addnewcontent