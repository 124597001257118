import React from 'react'
import { useEffect,useState } from 'react';
import AsyncSelect from 'react-select/async';
import Income from '../Line/Income';
import 'react-tippy/dist/tippy.css'
import {
  Tooltip,
} from 'react-tippy';
import idot from "../../../assets/menu.png";
import Bargraph from '../Bar/Bargraph';
import Barwithlinegraph from '../Bar/Barwithlinegraph';
const  Googlegrp= ({income}) => {
    const [activeiIndex,setiActiveIndex]=useState(0)
    const [loading, setLoading] = useState(true);
    const [options,setOptions]=useState(["Yesterday","Today","Weekly","Biweekly","Monthly","Quaterly","Yearly","Lifetime","Custom"])
    const [defaultOption,setDefaultOption]=useState({label:'Android'});
    const [selectedstartDate, setstartSelectedDate] = useState(null);
    const [selectedendDate, setendSelectedDate] = useState(null);
  
    const option_platforms=async()=>{
      return [
        {
          label:'Android'
      },
      {
          label:'Website'
      },
      {
          label:'IOS'
      },
      {
          label:'Android TV'
      }
      ]
    }
    const [oflag,setOflag]=useState(false)
    const handleDateSelection=(x,index)=>{
      console.log(x)
      setiActiveIndex(index)
      if(index === 8){
        setOflag(true)
      }else{
        setOflag(false)
      }
    }
    const handleDateChangeR = (e,int) => {
        if(int !== 0){
          setendSelectedDate(e.target.value);
        }else{
          setstartSelectedDate(e.target.value);
        }
      };
      const handleplatform=(e)=>{
        console.log(e)
       // setdeviceFiltered(Array.isArray(e)?e.map(x=>x.label):[]);
      }
  return (
    <div className='grchild'>
    <div className={oflag ? 'grdatepicker':'grdatepicker hidden'}>
          <div className='grdatepickerch'>
            <p>Select Start Date</p>
            <input
            type="date"
            value={selectedstartDate}
            onChange={(e)=>handleDateChangeR(e,0)}
            dateFormat="dd-MM-yyyy"
            />
          </div>
          <div className='grdatepickerch'>
            <p>Select End Date</p>
            <input
              type="date"
              value={selectedendDate}
              onChange={(e)=>handleDateChangeR(e,1)}
            />
          </div>
    </div>
    <div className="grsub">
      <h2>Google Adds Analytics <br/><p>Exclusive Data</p></h2>
      <AsyncSelect
      className="grselect"
                  cacheOptions
                  defaultOptions
                  getOptionLabel={e => e.label}
                  getOptionValue={e => e.label}
                  defaultValue={defaultOption}
                  loadOptions={option_platforms}
                  onChange={handleplatform}
                  placeholder='Select Platform'
      />
      {/* <ul>
        {options.map((x,index)=>{
          return <li key={index} className={index === activeiIndex ? "active":"inactive"} onClick={()=>handleDateSelection(x,index)}>{x}</li>
        })}
        <Tooltip
          // options
          title="Options"
          position="top"
          trigger="mouseenter"
        >
        <img src={idot}/>
        </Tooltip>
        <div>
      </div>
      </ul> */}
    </div>
    <div className='grvertical'>
    <Barwithlinegraph width={1200} height={400}/>
    </div>
  </div>
  )
}

export default Googlegrp