import React, { useState,useEffect } from 'react'
import DragDropComponent from './Dnd/DragDropComponent'
import { useParams } from 'react-router-dom'
import { useUserAuth } from '../../Context/UserAuthContext';
const initialLeftLabels = [
  { id: 'title', text: 'Title (Display title of your content)' },
  { id: 'cast', text: 'Cast (Actor and Actress , Can add one or multiple separated by commas)' },
  { id: 'description', text: 'Description ( Story synopsis or one line) ' },
  { id: 'releaseDate', text: 'Release Date ( Released/Release date)' },
  { id: 'published', text: 'Published (Is content visible in the application)' },
  { id: 'active', text: 'Active (Is content active, active means it will come in all search engine )'},
  {
    id: 'countryFiltered',
    text: 'Country Excluded from Streaming (Countries where you don’t want to show your content in the application or not to stream)',
  },
  {
    id: 'tags',
    text: 'Select Tags (Content Self Regulating Tags)',
  },
  {
    id: 'deviceFiltered',
    text: 'Select Platform to Stream (Supported platform to show this content, User can choose more than one platform)',
  },
  {
    id: 'category',
    text: 'Select Category (What is the category of content, Can choose more than one content)',
  },
  {
    id: 'subcategory',
    text: 'Select Subcategory (What is the sub category of content, Can choose more than one content)',
  },
  {
    id: 'censor',
    text: 'Is Censor Applicable (Is content censored or censor applicable)',
  },
  {
    id: 'censorCertificate',
    text: 'Censor Certificate (List show all possible censor rating)',
  },
  {
    id: 'uploadCensorCertificate',
    text: 'Upload Censor Certificate (Upload censor certificates)',
  },
  {
    id: 'genre',
    text: 'Genre (Choose all applicable genre for the content)',
  },
  {
    id: 'offlineStreaming',
    text: 'Have Offline Streaming (Is User can download videos)',
  },
  {
    id: 'ageGroup',
    text: 'Minimum Age Group Can Watch (Age group can watch the content)',
  },
  {
    id: 'director',
    text: 'Director (Director name, if more than one director add with commas)',
  },
  {
    id: 'production',
    text: 'Production (Name of production)',
  },
  {
    id: 'otherCastCrew',
    text: 'Other Cast & Crew (All other cast crew formatted with commas)',
  },
  {
    id: 'producer',
    text: 'Producer (Name of the producer)',
  },
  {
    id: 'freelyAvailable',
    text: 'Freely Available (Is content free to watch)',
  },
  {
    id: 'canWatchWithoutLogin',
    text: 'Can Watch Without Login (Content can be watched without login also)',
  },
  {
    id: 'portraitPosterSmall',
    text: 'Portrait Poster Small (Portrait poster small)',
  },
  {
    id: 'portraitPosterNormal',
    text: 'Portrait Poster Normal (Portrait poster normal)',
  },
  {
    id: 'webPortraitPosterNormal',
    text: 'Web Portrait Poster Normal (Only for web)',
  },
  {
    id: 'landscapePosterSmall',
    text: 'Landscape Poster Small (Landscape poster small)',
  },
  {
    id: 'landscapePosterNormal',
    text: 'Landscape Poster Normal (Landscape poster normal)',
  },
  {
    id: 'webLandscapePosterNormal',
    text: 'Web Landscape Poster Normal (Web landscape poster normal)',
  },
  {
    id: 'genericTrailer',
    text: 'Generic Trailer (Common trailer for all platforms)',
  },
  {
    id: 'freeTrailer',
    text: 'Free Trailer (Trailer can be watched by free users)',
  },
  {
    id: 'paidTrailer',
    text: 'Paid Trailer (Trailer can be watched by paid users)',
  },
  {
    id: 'trailerAvailable',
    text: 'Trailer Available (Is trailer available for content)',
  },
  {
    id: 'canShowInTV',
    text: 'Can Show in TV (NA)',
  },
  {
    id: 'haveSeason',
    text: 'Have Season',
  },
];
const AddTemplate = () => {
  let {templateName,templateId} = useParams();
  const {listTemplates} = useUserAuth()
  const [temp,setTemp]=useState([])
  const [leftOptions,setLeftOptions]=useState(initialLeftLabels)
  const [rightOptions,setRightOptions]=useState([])
  const [pro,setPro]=useState(false)
  useEffect(()=>{
    const fetchData=async()=>{
      let type="draft"
      let res = await listTemplates(type)
      let data = res.data.data.filter(x=>x.title === templateName)
      if(data &&  data.length > 0 && data[0].data[0] && data[0].data[0].id){
        let defLabel = data[0].data; 
        const filteredLabels = initialLeftLabels.filter(
          label => !defLabel.some(removeLabel => removeLabel.id === label.id)
        );
        setLeftOptions(filteredLabels)
        setRightOptions(defLabel)
        setPro(true)
        //setTemp(data[0].data);
       // return data[0].data
      }else{
        setPro(true)
      }
    
    }
    fetchData()
  },[])
  return (
    <div className='Temp subcr'> 
        <div className='path'>
        <span className="head">
                <span className="dashhead m-3">
                  <h3> Contents | <a href="/contents/options">Templates</a> | {templateName} </h3>
                </span>
              </span>
       
        </div>
        {pro ?
        <DragDropComponent templateName={templateName} templateId={templateId}  left={leftOptions} right={rightOptions}/>:"loading"}
    </div>
  )
}

export default AddTemplate