import React,{useEffect, useState} from "react";
import { createContext,useContext } from "react";
import Instance from "../../Objects/Axios";
import MultipartAPI from "../../Objects/MultipartAPI";
const userAuthContext = createContext();
export function UserAuthContextProvider({ children }) {
  const getChartData=async(url)=>{
      try{
          return Instance.get(url)
          .then(async res=>{  
            //setHomeData(res.data.data)
              return res
          })
      }catch(err){
          return err
      }
  }
  const addTemplates =async(params)=>{
    try{
        return Instance.post('/addTemplates',params)
        .then(async res=>{   
            return res
        })
    }catch(err){
        return err
    }
  }
  const updateTemplates =async(params)=>{
    try{
        return Instance.post('/updateTemplate',params)
        .then(async res=>{  
          console.log(res)
            return res
        })
    }catch(err){
        return err
    }
  }
  const listTemplates =async(type)=>{
    try{
        return Instance.get(`/listTemplates?type=${type}`)
        .then((res)=>{  
            return res
        })
    }catch(err){
        return err
    }
  }
  const listContents =async(type)=>{
    try{
        return Instance.get(`/listContents`)
        .then((res)=>{  
            return res
        })
    }catch(err){
        return err
    }
  }
  const addContents = async(formData)=>{
    try{
      return MultipartAPI.put('/addContent',formData)
      .then((res)=>{  
          return res
      })
    }catch(err){
        return err
    }
  }
  const updateContents = async(formData)=>{
    try{
      return MultipartAPI.post('/updateContent',formData)
      .then((res)=>{  
          return res
      })
    }catch(err){
        return err
    }
  }

  const arrangeContents = async(param)=>{
  return Instance.post('/arrangeContents',param)
  .then((res) => {
    return res
   })
  .catch(err=>{
      console.log(err)
  })
  }
  return (
    <userAuthContext.Provider
      value={{
        getChartData,
        listTemplates,
        addTemplates,
        updateTemplates,
        listContents,
        addContents,
        updateContents,
        arrangeContents
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}