import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useUserAuth } from '../Context/UserAuthContext';

// const initialItems = [
//   { id: '1', content: 'Item 1', rank: 1 },
//   { id: '2', content: 'Item 2', rank: 2 },
//   { id: '3', content: 'Item 3', rank: 3 },
//   { id: '4', content: 'Item 4', rank: 4 },
// ];
const static_url = process.env.REACT_APP_CDN_STATIC;
const SortableContents = ({data,handleClose}) => {
  const {arrangeContents} = useUserAuth();
  const [items, setItems] = useState(data);
    useEffect(()=>{
        console.log(items)
    },[])
  // Sort items by rank on initial render and any updates to items
  const sortedItems = [...items].sort((a, b) => a.rank - b.rank);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(sortedItems);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    // Update ranks based on the new order
    const updatedItems = reorderedItems.map((item, index) => ({
      ...item,
      rank: index + 1, // Update the rank to reflect the new position
    }));
    console.log(updatedItems)
    const filteredContents = updatedItems.map(({ _id, rank }) => ({ _id, rank }));
    console.log("final",filteredContents)
    setItems(updatedItems);
    arrangeContentsBasedOnRank(filteredContents)
  
  };

  const arrangeContentsBasedOnRank=async(filteredContents)=>{
    const param  = {
      contents:filteredContents
    }
    let res  = await arrangeContents(param)
    console.log(res)
  }
  return (
    <div className="editMode">
    <p className='customBtnForClose' ><span>Edit Mode - Drag and Drop the contents to update rank ... </span> <span onClick={handleClose}>Close</span></p>
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        
        {/* {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
          >
          
            {sortedItems.map((item, index) => (
              <Draggable key={item._id} draggableId={item._id} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      padding: '16px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      backgroundColor: '#eee',
                      ...provided.draggableProps.style,
                    }}
                  >
                  {item.rank} : {item.title}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )} */}
       
       {(provided) => (
       <table className="table table-striped" 
       {...provided.droppableProps}
       ref={provided.innerRef}
       >
                <thead>
                    <tr>
                    <th scope="col">No.</th>
                    <th scope="col">Thumbnail</th>
                    <th scope="col">Name</th>
                    <th scope="col">Director</th>
                    <th scope="col">Release</th>
                    </tr>
                </thead>
                <tbody>
            {sortedItems.map((q,index)=>(
               <Draggable key={q._id} draggableId={q._id} index={index}>
                {(provided) => (
                    <tr key={index + 1} id={`${q._id}`}  
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                                                <td>{q.rank}</td>
                                                <td><img style={{'width':'180px' , 'height':'120px',borderRadius:'4px'}} src={ static_url +'/' +q.awsStaticResourcePath+'/'+q.landscapePosterIdNormal}/></td>
                                                <td>{q.title} <br/> <span className="limited-text">{q.description.substring(1, 250) + "..."}</span></td>
                                                <td>{q.director}</td>
                                                <td>{q.releaseDate}</td>
                    </tr>
                )}
                </Draggable>
              ))}
                {provided.placeholder}
                </tbody>
            </table>
       )}
      </Droppable>
    </DragDropContext>
    </div>
  );
};

export default SortableContents;
