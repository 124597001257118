import React, { useEffect,useState } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import { useUserAuth } from '../../Context/UserAuthContext';
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042','#e24585','#f1144d'];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`} 
    </text>
  );
};

export default function Piegraph({width,height}) {
  const {getChartData} = useUserAuth();
  const [loading, setLoading] = useState(true);
  const [metaData,setMetaData]=useState([])
  const loadGraphData=async()=>{
    setLoading(true)
    let currentTime = new Date().getTime()
    let startTime = currentTime - 120*24*60*60*1000;
    let endTime = currentTime;
    let API_URL = `/dashboardJalwa?platform=Android Phone&startTime=${startTime}&endTime=${endTime}&timeFrame=custom&type=user`;
    let RealData = await getChartData(API_URL);
    setMetaData(RealData.data.data.user.custom)
    setLoading(false)
  }
//4853
  useEffect(()=>{
    loadGraphData()
  },[])
  let customCSS={
    width:"285px",
    height:"280px",
    borderRadius:"50%",
    marginLeft:"100px",
    marginTop:"50px"
  }

    return (
        <>
        {loading ? 
        <div className="skeleton" style={customCSS}></div>:
        <>
        <PieChart width={width-100} height={height}>
          <Pie
            data={metaData}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={140}
            fill="#8884d8"
            dataKey="count"
          >
            {metaData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
        <div className='customPie'>
        {metaData.map((x,index)=>{
            return(
                <div className="customPieColor">
                 <div className="customPieColorBox" style={{ backgroundColor: COLORS[index] }}></div><p>{x._id}</p>
                </div>
               
            )
        })}
        </div>
        </>
        }
        </>
    );
}
