// src/DragDropComponent.jsx
import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from '../../../Context/UserAuthContext';
import { Toaster } from 'react-hot-toast';
import toaster from '../../../../Objects/toaster';
const DragDropComponent = ({templateName,left,right,templateId}) => {
    const navigate = useNavigate();
    const {addTemplates,updateTemplates}=useUserAuth();
  // State to manage left and right columns
  const [tempName,setTempName]=useState(templateName);
  const [columns, setColumns] = useState({  
    left:left,
    right:right
  });
  // Handler for when dragging ends
  const onDragEnd = (result) => {
    const { source, destination } = result;
    // If no destination (e.g., dropped outside any droppable area), do nothing
    if (!destination) return;

    // If the item is dropped in the same position, do nothing
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const sourceColumn = columns[source.droppableId];
    const destinationColumn = columns[destination.droppableId];

    // Moving within the same column
    if (source.droppableId === destination.droppableId) {
      const reordered = Array.from(sourceColumn);
      const [movedItem] = reordered.splice(source.index, 1);
      reordered.splice(destination.index, 0, movedItem);

      setColumns({
        ...columns,
        [source.droppableId]: reordered,
      });
    } else {
      // Moving to a different column
      const sourceClone = Array.from(sourceColumn);
      const destClone = Array.from(destinationColumn);
      const [movedItem] = sourceClone.splice(source.index, 1);
      destClone.splice(destination.index, 0, movedItem);

      setColumns({
        ...columns,
        [source.droppableId]: sourceClone,
        [destination.droppableId]: destClone,
      });
    }
  };
  const handlePublishTemplate=async()=>{
    let tempArr = [];
    let obj = {}
    //object creation
    obj["title"] = templateName;
    obj["description"] = "updating templates";
    obj["isDraft"] = false;
    obj["data"] = columns.right;
    obj["templateId"] = templateId;
    let res = await updateTemplates(obj);
    toaster(res.data.message,"success")
    if(!res.data.error){
      setTimeout(()=>{
        navigate("/contents/options")
      },[1500])
      
    }
  }
  const handleGoBack=()=>{
    navigate(-1)
  }
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="container">
        {/* Left Column: Available Labels */}
        <Droppable droppableId="left">
          {(provided, snapshot) => (
            <div
              className={`column ${snapshot.isDraggingOver ? 'dragging-over' : ''}`}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <h3>Available Fields</h3>
              {columns.left.map((label, index) => (
                <Draggable key={label.id} draggableId={label.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      className={`label ${snapshot.isDragging ? 'dragging' : ''}`}
                      ref={provided.innerRef}
                      {...provided.draggableProps}  
                      {...provided.dragHandleProps}
                    >
                      {label.text}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>

        {/* Right Column: Selected Labels */}
        <Droppable droppableId="right">
          {(provided, snapshot) => (
            <div
              className={`column ${snapshot.isDraggingOver ? 'dragging-over' : ''}`}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <h3>{tempName}</h3>
              {columns.right.map((label, index) => (
                <Draggable key={label.id} draggableId={label.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      className={`label ${snapshot.isDragging ? 'dragging' : ''}`}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {label.text}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              <button className="btnSubmit warn" onClick={handlePublishTemplate}>Publish Template</button>
              <button className="btnSubmit">Save To Draft</button>
              <button className="btnSubmit danger" onClick={handleGoBack}>Cancel</button>
            </div>
          )}
        </Droppable>
      </div>
      <Toaster/>
    </DragDropContext>
  );
};

export default DragDropComponent;
